import $ from "jquery";
import { initAccordion } from "./animations.js";
let current_page = 1;

window.addEventListener("load", function() {
  adjustTopMargin();
});

$(document).ready(function() {
  restoreSelectedItems();
  // Lorsque l'on rempli la barre de recherche
  $("body").on("change", ".ajax-search", function(e) {
    e.stopPropagation();
    e.preventDefault();
    current_page = 1;
    sendAjax();
  });

  // Lorsque l'on clique sur l'une des taxonomies
  $("body").on("click", ".ajax-taxonomy-all", function(e) {
    $(this).attr("checked", "checked");
    console.log("all");
    e.stopPropagation();
    $(this)
      .closest(".wrap-taxonomy")
      .find(".ajax-taxonomy")
      .removeAttr("checked");
    current_page = 1;
    sendAjax();
  });

  // Lorsque l'on clique sur l'un des tags
  $("body").on("click", ".ajax-tag-all", function(e) {
    $(this).attr("checked", "checked");
    console.log("all");
    e.stopPropagation();
    $(this)
      .closest(".wrap-taxonomy")
      .find(".ajax-taxonomy")
      .removeAttr("checked");
    current_page = 1;
    sendAjax();
  });

  // Lorsque l'on clique sur l'une des taxonomies
  $("body").on("click", ".ajax-taxonomy", function(e) {
    console.log("tx");
    e.stopPropagation();
    $(this)
      .closest(".wrap-taxonomy")
      .find(".ajax-taxonomy-all")
      .removeAttr("checked");
    current_page = 1;
    sendAjax();
  });

  // Lorsque l'on clique sur la pagination
  $("body").on("click", ".ajax-page", function(e) {
    e.stopPropagation();
    e.preventDefault();
    current_page = $(this).data("step");
    sendAjax();
  });

  // Lorsque l'on clique sur le bouton reset
  $("body").on("click", ".reset-button", function(e) {
    e.preventDefault();
    $(".ajax-taxonomy, .ajax-taxonomy-all, .ajax-tag-all").prop(
      "checked",
      false
    );
    current_page = 1;
    sendAjax();
  });

  // Lorsque la requête AJAX est réussie, afficher les filtres actifs
  function restoreSelectedItems() {
    $(".ajax-taxonomy, .ajax-tag-all").each(function() {
      const itemName = $(this)
        .next()
        .text();
      const itemId = $(this).attr("id");
      const isSelected = $(this).attr("checked") === "checked";

      if (isSelected) {
        $(".selected-items").append(
          `<div class="selected-item px-2 py-2 w-fit cursor-pointer gap-1 flex items-center rounded-md" data-id="${itemId}">${itemName} <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M777.856 280.192l-33.92-33.952-231.872 231.872-231.84-231.872-33.984 33.888 231.872 231.904-231.84 231.84 33.888 33.984 231.904-231.904 231.84 231.872 33.952-33.888-231.872-231.904z"/></svg></div>`
        );
      }
    });
  }

  // Gère le clique sur les filtres reset
  $("body").on("click", ".selected-item", function(e) {
    e.preventDefault();

    const $this = $(this);
    const itemId = $this.attr("data-id");
    $this.remove();

    $(`#${itemId}`).prop("checked", false);
    sendAjax();
  });

  function sendAjax() {
    let allItems = $(".ajax-taxonomy:checked");
    let taxonomies = "";
    let search = $(".ajax-search").val();
    let page = current_page;
    let myBlock = "ajax-block";

    // On cache les items courants
    $("." + myBlock)
      .find(".post-item")
      .hide();

    // On scroll vers le haut du bloc
    scrollToTopOfTheSection(myBlock);

    // Affichage du loader
    $(".loader").show();
    $(".tool-pagination").hide();

    // Récupération des taxonomies sélectionnées
    allItems.each(function(i, element) {
      let slug = $(element).attr("name");
      let tx = $(element).attr("data-tx");
      taxonomies += "----" + tx + "---" + slug;
    });

    // Formattage des paramètres
    const datas = {
      search: search,
      taxonomies: taxonomies,
      step: page,
    };

    // Envoi de la requête AJAX
    $.ajax({
      url: current_url_root,
      method: "GET",
      data: datas,
      success: function(data) {
        // Remplace le DOM des items

        $("." + myBlock).html(
          $(data)
            .find("." + myBlock)
            .html()
        );

        // Remplace l'url de navigation
        history.pushState(
          "",
          "",
          current_url_root +
            "?" +
            "search=" +
            search +
            "&taxonomies=" +
            taxonomies +
            "&step=" +
            page
        );
        initAccordion();
        adjustTopMargin();

        restoreSelectedItems();
      },
      error: function(data) {
        console.log(data);
      },
    });
  }
});

// === DYNAMIC HIGHLIGHT POST MARGIN TOP ===
function adjustTopMargin() {
  const highlightPosts = document.querySelectorAll(".highlight-post");

  highlightPosts.forEach((highlightPost) => {
    const target = highlightPost.querySelector(".post-item__content--target");

    if (target) {
      const targetHeight = target.offsetHeight;
      const margin = targetHeight * 0.35;
      highlightPost.style.marginTop = `${margin}px`;
    }
  });
}

function scrollToTopOfTheSection(myBlock) {
  window.scrollTo({
    top: $("." + myBlock).offset().top,
    behavior: "smooth",
  });
}
